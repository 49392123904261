var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "pt-56" },
        [
          _c(
            "v-tabs",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { grow: "", "background-color": "bg", color: "primary" },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            _vm._l(_vm.tabs, function (item) {
              return _c("v-tab", { key: item.id }, [
                _c("span", { staticClass: "subtitle-2" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
              ])
            }),
            1
          ),
          _c("br"),
          _c(
            "v-row",
            { staticClass: "pt-3" },
            [
              _vm.tabContentList.length > 0
                ? _vm._l(_vm.tabContentList, function (card) {
                    return _c(
                      "v-col",
                      {
                        key: card._id,
                        staticClass: "px-4 py-2",
                        attrs: { cols: "12" },
                      },
                      [
                        _c(
                          "v-container",
                          [
                            _c(
                              "v-row",
                              {
                                staticClass:
                                  "card rounded vipCard1 align-center",
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "card__img",
                                    attrs: { cols: "6" },
                                  },
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        src: _vm.baseImgUrl + card.image,
                                        position: "left top",
                                        "max-height": "160",
                                        contain: "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "card__info text-right title--text",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "card__info__name mb-3" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.getCardTitle(card)) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "card__info__no" },
                                      [_vm._v(" " + _vm._s(card.bank_no) + " ")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "card__close ma-2" },
                                  [
                                    _c(
                                      "v-btn",
                                      { attrs: { icon: "" } },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: { color: "card1" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showRemoveBankCardDialog(
                                                  card.bank_no
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" mdi-close ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  })
                : _vm._e(),
              _vm.tabContentList.length < 3
                ? _c(
                    "v-col",
                    { staticClass: "px-4 py-2", attrs: { cols: "12" } },
                    [
                      _c(
                        "div",
                        { staticClass: "card2 rounded card" },
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-row",
                                { staticClass: "card rounded align-center" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "card__info text-right title--text",
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { text: "" },
                                          on: { click: _vm.addBankCard },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "rounded-circle secondary",
                                              attrs: { color: "card1" },
                                            },
                                            [_vm._v(" mdi-plus ")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ml-3 subtitle-1 title-text",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.addCardText) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "text-center subtitle-2 pt-0 comment--text pb-2",
                  attrs: { cols: "12" },
                },
                [_c("span", [_vm._v(" " + _vm._s(_vm.cardLimitText) + " ")])]
              ),
            ],
            1
          ),
          _c(
            "Dialog",
            { attrs: { dialog: _vm.dialog.status, width: 280, height: 150 } },
            [
              _vm.dialog.type === "baseInfo"
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "text px-6",
                        attrs: { slot: "text" },
                        slot: "text",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("myCenter.firstAddBankCard")) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "subtitle",
                        attrs: { slot: "btn", text: "" },
                        on: {
                          click: function ($event) {
                            _vm.dialog.status = false
                          },
                        },
                        slot: "btn",
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          slot: "btn",
                          to: "/user/personal",
                          text: "",
                          color: "primary",
                        },
                        slot: "btn",
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("global.confirmation")) + " "
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
              _vm.dialog.type === "removeBankCard"
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "text px-6",
                        attrs: { slot: "text" },
                        slot: "text",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.tab
                                ? _vm.$t("flashMessage.deleteUsdtCard")
                                : _vm.$t("flashMessage.confirmDeleteBankCard")
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        staticClass: "subtitle",
                        attrs: { slot: "btn", text: "" },
                        on: {
                          click: function ($event) {
                            _vm.dialog.status = false
                          },
                        },
                        slot: "btn",
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { slot: "btn", text: "", color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteCard(_vm.form.bank_no)
                          },
                        },
                        slot: "btn",
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("global.confirmation")) + " "
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }