<template>
  <div>
    <v-container class="pt-56">
      <v-row class="pt-4">
        <!-- TAB -->
        <v-tabs
          v-model="tab"
          grow
          background-color="bg"
          color="primary"
        >
          <v-tab
            v-for="item in tabs"
            :key="item.id"
          >
            <span class="subtitle-2">{{ item.name }}</span>
          </v-tab>
        </v-tabs><br>
        <!-- 使用者卡片列表,tabContentList會依照tab對應顯示資料(銀行卡列表資料或虛擬帳戶列表資料) -->
        <template v-if="tabContentList.length > 0">
          <v-col
            v-for="card in tabContentList"
            :key="card._id"
            cols="12"
            class="px-4 py-2"
          >
            <v-container>
              <v-row class="card rounded bank-cards align-center">
                <!-- 銀行logo -->
                <v-col
                  cols="6"
                  class="d-flex flex-column h-100 pa-6 pr-0"
                >
                  <div>
                    <v-img
                      class="mt-2"
                      :src="baseImgUrl + card.image"
                      :width="46"
                      :height="46"
                      cover
                    />
                  </div>

                  <v-spacer />

                  <div class="white--text">
                    {{ getCardTitle(card) }}
                  </div>
                </v-col>

                <!-- 銀行說明文字 -->
                <v-col
                  cols="6"
                  class="d-flex justify-end align-end h-100 pa-6 white--text pl-0"
                >
                  {{ replaceBankCardNo(card.bank_no).slice(replaceBankCardNo(card.bank_no).length - 8, replaceBankCardNo(card.bank_no).length) }} {{ card.slug?`( ${card.slug} )`:'' }}
                </v-col>

                <!-- 刪除按鈕 -->
                <div class="card__close ma-2">
                  <v-btn icon>
                    <v-icon
                      color="card1"
                      @click="showRemoveBankCardDialog(card.bank_no)"
                    >
                      mdi-close
                    </v-icon>
                  </v-btn>
                </div>
              </v-row>
            </v-container>
          </v-col>
        </template>

        <!-- 新增卡片 -->
        <v-col
          v-if="tabContentList.length < 3"
          cols="12"
          class="px-4 py-2"
        >
          <div
            class="rounded"
            :class="[{ 'white': theme === 'light' }]"
            :style="{ border: theme === 'light' ? `1px dashed rgba(0, 0, 0, 0.2) !important` : `1px dashed rgba(255, 255, 255, 0.5) !important` }"
          >
            <v-container>
              <v-row class="card rounded align-center">
                <div class="card__info text-right title--text">
                  <!-- 新增卡片 btn -->
                  <v-btn
                    text
                    class="pa-0"
                    @click="addBankCard"
                  >
                    <v-icon
                      class="rounded-circle primary"
                      color="black"
                    >
                      mdi-plus
                    </v-icon>
                    <span class="ml-3 subtitle-1 title--text">
                      {{ addCardText }}
                    </span>
                  </v-btn>
                </div>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>

      <!-- 說明文字 -->
      <v-row>
        <v-col
          cols="12"
          class="text-center subtitle-2 pt-0 title--text pb-2"
        >
          <span>
            {{ cardLimitText }}
          </span>
        </v-col>
      </v-row>

      <!-- Dialog (如果沒有完善個人資料 / 如果要刪除卡片) -->
      <DialogTemplate3
        :dialog="dialog.status"
        :width="280"
        :height="200"
      >
        <!-- 如果沒有完善個人資料 -->
        <template v-if="dialog.type === 'baseInfo'">
          <div
            slot="title"
            class="text-center w-100 subtitle-1 py-2 white--text"
          >
            {{ $t('myCenter.completeInfo') }}
          </div>
          <div
            slot="text"
            class="text px-6 mt-6"
          >
            {{ $t('myCenter.firstAddBankCard') }}
          </div>

          <v-btn
            slot="btn"
            text
            class="subtitle"
            @click="dialog.status = false"
          >
            {{ $t('global.cancel') }}
          </v-btn>
          <v-btn
            slot="btn"
            to="/user/personal"
            text
            color="primary"
          >
            {{ $t('global.confirmation') }}
          </v-btn>
        </template>

        <!-- 如果要刪除卡片 -->
        <template v-if="dialog.type === 'removeBankCard'">
          <div
            slot="title"
            class="text-center w-100 subtitle-1 py-2 primary--text"
          >
            -
          </div>
          <div
            slot="text"
            class="text px-6 mt-6"
          >
            {{ tab ? $t('flashMessage.deleteUsdtCard') : $t('flashMessage.confirmDeleteBankCard') }}
          </div>
          <v-spacer />
          <v-btn
            slot="btn"
            text
            class="subtitle"
            @click="dialog.status = false"
          >
            {{ $t('global.cancel') }}
          </v-btn>
          <v-btn
            slot="btn"
            text
            color="primary"
            @click="deleteCard(form.bank_no)"
          >
            {{ $t('global.confirmation') }}
          </v-btn>
        </template>
      </DialogTemplate3>
    </v-container>
  </div>
</template>

<script>
import cardsMixin from '../mixins/cardsMixin'

export default {
  mixins: [cardsMixin],
}
</script>

<style lang="scss" scoped>
.card {
	position: relative;
	height: 180px;

	&__img {
		opacity: 0.3;
	}

	&__info {
		position: absolute;
		right: 24px;
		bottom: 25px;
	}

	&__close {
		position: absolute;
		top: 0;
		right: 0;
	}
}
.bank-cards {
  background: linear-gradient(180deg, #E32A10 0%, #9F1E0B 100%);
}
.card__info__no{
 font-size: 14px;
}
</style>
