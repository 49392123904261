
import { mapActions, mapGetters } from 'vuex'
import { resStatus } from '@/utils/resUtils'

import Dialog from '@/components/dialog'
import DialogTemplate3 from '@/components/dialog/DialogTemplate3'

export default {
  components: {
    Dialog,
    DialogTemplate3,
  },

  data: () => ({
    tab: 0,
    dialog: {
      status: false,
      type: '',
    },

    userBaseInfoOk: true,
    userBankCard: [],
    userUsdtCard: [],
    cryptoList: [], // 用於判斷是否需要顯示虛擬列表
    form: {
      bank_no: '',
    },
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'theme', 'currencySymbol']),
    tabs() {
      const tabList = [
        {
          name: this.$t('myCenter.bankCardTab'),
          id: 'bankTab',
        },
        ...(this.cryptoList.length > 0
          ? [{
              name: this.$t('myCenter.usdtCardTab'),
              id: 'usdtTab',
            }]
          : []),
      ]
      return tabList
    },
    /**
     * 點擊時，btn 主色外框
     * @returns {string} border style (主色外框)
     */
    activePaymentItem() {
      return {
        border: `1px solid ${this.primaryColor} !important`,
      }
    },
    primaryColor() {
      return this.$vuetify.theme.themes[this.theme]?.primary || ''
    },
    tabContentList() {
      // 依照當前tab決定顯示銀行卡列表資料還是虛擬幣列表資料
      if (this.tab) return this.userUsdtCard
      return this.userBankCard
    },
    addCardText() {
      if (this.tab) return this.$t('myCenter.addUsdtCard')
      return this.$t('flashMessage.addCard')
    },
    cardLimitText() {
      if (this.tab) return this.$t('myCenter.usdtCardLimit')
      return this.$t('myCenter.cardLimit')
    },

  },

  watch: {
    'dialog.status'(nowStatus) {
      if (!nowStatus) this.form.bank_no = ''
    },
  },
  async mounted() {
    this.set_toolbar_title(this.$t('global.cards'))
    this.getUserBankCardList()
    await this.getBankList()
    // 判斷router是否有帶參數,決定頁籤
    this.tab = this.$route.query.type === 'usdt' ? 1 : 0
  },
  methods: {
    ...mapActions([
      'set_toolbar_title',
      'get_user_bankcard_list',
      'delete_user_bank_card',
      'delete_user_usdt_card',
      'show_alert',
      'get_bank_list',
    ]),
    async getBankList() {
      const bankList = await this.get_bank_list()
      resStatus(bankList, this.setBanckList)
    },

    setBanckList(bankList) {
      this.cryptoList = bankList.cryptocurrencies
    },
    getCardTitle(card) {
      return card.ifsc_code || `${card.bank_name} ${card.slug ? `( ${card.slug} )` : ''}`
    },

    getUserBankCardListSuccess(data) {
      const { bankcards, completed_baseinfo, cryptocurrency_accounts } = data
      if (completed_baseinfo !== 'Y') {
        this.userBaseInfoOk = false
        this.dialog.status = true
        this.dialog.type = 'baseInfo'
      }
      this.userBankCard = bankcards
      this.userUsdtCard = cryptocurrency_accounts
    },
    async getUserBankCardList() {
      const res = await this.get_user_bankcard_list()
      resStatus(res, this.getUserBankCardListSuccess)
    },
    replaceBankCardNo(bankCarNo) {
      const strLength = bankCarNo.length
      let secret = '＊'
      for (let i = 1; i < strLength - 4; i++) {
        secret += '＊'
      }
      return secret + bankCarNo.substr(strLength - 4, 4)
    },

    showRemoveBankCardDialog(bank_no) {
      this.dialog.status = true
      this.dialog.type = 'removeBankCard'
      this.form.bank_no = bank_no
    },
    deleteCard(no) {
      if (this.tab) return this.deleteUserUsdtCard(no)
      return this.deleteUserBankCard(no)
    },
    deleteUserBankCardSuccess(data) {
      const { bankcards } = data
      this.userBankCard = bankcards

      this.show_alert({
        icon: 'done',
        text: this.$t('global.delete') + this.$t('global.success'),
      })
    },
    deleteUserUsdtCardSuccess(data) {
      const { cryptocurrency_accounts } = data
      this.userUsdtCard = cryptocurrency_accounts

      this.show_alert({
        icon: 'done',
        text: this.$t('global.delete') + this.$t('global.success'),
      })
    },
    deleteUserCardFail() {
      this.show_alert({
        icon: 'fail',
        text: this.$t('global.delete') + this.$t('global.fail'),
      })
    },

    async deleteUserBankCard(bank_no = '') {
      this.dialog.status = false
      const updateUserBankCardData = await this.delete_user_bank_card({
        bank_no,
      })
      resStatus(
        updateUserBankCardData,
        this.deleteUserBankCardSuccess,
        this.deleteUserCardFail,
      )
    },
    async deleteUserUsdtCard(bank_no = '') {
      this.dialog.status = false
      const updaterUsdtCardData = await this.delete_user_usdt_card({
        bank_no,
      })
      resStatus(
        updaterUsdtCardData,
        this.deleteUserUsdtCardSuccess,
        this.deleteUserCardFail,
      )
    },

    /**
     * 新增銀行卡
     * @date 2021-08-13
     */
    addBankCard() {
      if (this.userBaseInfoOk) return this.$router.replace({ path: `/user/add-card?type=${this.tab ? 'usdt' : 'bank'}` })
      this.dialog.status = true
      this.dialog.type = 'baseInfo'
    },
  },
}
